<template>

  <b-card-code
    no-body
    title="Templates"
  >

    <b-overlay
      :show="loading"
      rounded="sm"
    >

      <b-table
        striped
        hover
        :items="templates"
        :fields="fields"
      >

        <template #cell(id)="data">
          <span v-b-tooltip.hover.top="data.item.id">
            #
          </span>

        </template>

        <template #cell(pages)="data">

          <router-link :to="{ name: 'documents-columns', params: { templateId: data.item.id }}">
            <b-button

              variant="outline-primary"
              size="sm"
              class="btn-tour-finish mr-1"
            >
              <feather-icon
                icon="ListIcon"
                class="mr-50"
              />
              Columns
            </b-button>
          </router-link>
        </template>

        <template #cell(createdAt)="data">
          {{ data.item.createdAt|formatDateTime }}
        </template>

        <template #cell(actions)="data">
          <router-link :to="{ name: 'edit-template', params: { templateId: data.item.id }}">
            <b-button

              variant="primary"
              size="sm"
              class="btn-tour-finish mr-1"
            >
              Edit
            </b-button>

          </router-link>

          <a
            class="text-danger"
            @click="remove(data.item.id)"
          >Remove</a>
        </template>

      </b-table>

      <div
        v-if="rows > 25"
        class="mt-1 d-flex justify-content-center"
      >
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
          @change="handlePageChange"
        />
      </div>

    </b-overlay>

  </b-card-code>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, VBTooltip, BPagination, BSpinner, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BPagination,
    BSpinner,
    ToastificationContent,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      templates: [],
      fields: ['id', {
        key: 'label',
        label: 'label',
        class: 'max_with_200',
      }, 'pages', { defaultBqDataSet: 'Bq DataSet' },
      {
        key: 'defaultBqTable',
        label: 'Bq Table',
        class: 'max_with_200',
      }, 'createdAt', 'actions'],
      currentPage: 1,
      perPage: 25,
      lastPage: 0,
      rows: 0,
    }
  },

  created() {
    this.loadTemplates()
  },

  methods: {

    handlePageChange(value) {
      this.page = value

      this.loadTemplates(this.page)
    },

    loadTemplates(page = 1) {
      this.loading = true

      this.$store.dispatch('documentTemplate/fetchAll', page)
        .then(result => {
          this.templates = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },

    remove(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger ',
          cancelButton: 'btn btn-outline-primary  ml-1',
        },
        buttonsStyling: false,

      }).then(result => {
        if (result.value === true) {
          this.loading = true

          this.$store.dispatch('documentTemplate/remove', id).then(
            result => {
              console.log(result)
              this.loadTemplates()
            },
            error => {
              console.log(err)
            },
          ).finally(() => {
            this.loading = false
          })
        }
      },
      error => {
        console.log(err)
      })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
